import React from 'react';
import { Col, Row } from 'antd/es/grid';
import Typography from 'antd/es/typography';
import { Container, LandingRow, Button } from '@axmit/clp-library';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from 'components/Seo';
import StickyBtnBlock from 'components/StickyBtnBlock';
import { redirectHelper } from 'helpers/redirect.helper';
import { getTranslates } from 'helpers/translate.helper';
import { useTranslation } from 'react-i18next';

export default ({ data }) => {
  const {
    i18n: { language }
  } = useTranslation();

  return (
    <>
      <div className="land-img land-img_ph-key-desktop landing-wrapper--img hidden_mob">
        <Img fixed={data.KeyDesktop.childImageSharp.fixed} alt="key" imgStyle={{ objectFit: 'contain' }} />
      </div>

      <div className="landing-wrapper philosophy-landing-wrapper">
        <SEO title={getTranslates(data.allStrapiClpLandCommon, 'header__philosophy', language)} />

        <Container>
          <Row type="flex" align="top">
            <Col xs={24} lg={24} xl={12}>
              <>
                <div className="mt-50 hidden_mob" />

                <Typography.Title className="clp-typography-title clp-typography-title_size_md mb-25">
                  {getTranslates(data.allStrapiClpPhilosophyPage, 'header', language)}
                </Typography.Title>

                <Typography.Text className="clp-typography-text clp-typography-text_size_md">
                  {[1, 2, 3, 4].map(i => (
                    <p key={i} className="content-card mb-25">
                      {getTranslates(data.allStrapiClpPhilosophyPage, `description__par${i}`, language)}
                    </p>
                  ))}
                </Typography.Text>

                <div className="mb-50 hidden_mob" />

                <div className="land-img_ph-key-mobile hidden_desk">
                  <Img fixed={data.KeyMobile.childImageSharp.fixed} alt="key small" imgStyle={{ objectFit: 'contain' }} />
                </div>
              </>
            </Col>

            <Col xs={0} lg={{ span: 20, offset: 2 }} xl={{ span: 12, offset: 0 }}>
              <div className="land-img__wrapper-half land-img__wrapper-half_right">
                <Img fluid={data.PhilosophyPreview.childImageSharp.fluid} alt="Smiling happily" />
              </div>
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="center" className="down-navigation down-navigation_margin_sm">
            <Col>
              <Button type="link" onClick={scrollToDiscover} className="down-navigation__button">
                <Img fixed={data.Down.childImageSharp.fixed} alt="down" />
              </Button>
            </Col>
          </Row>
        </Container>

        <div className="landing-white-wrapper pt-50 pb-50">
          <Container>
            <LandingRow
              revertOrder
              leftSlot={
                <div className="land-img_ph-portrait-desktop hidden_mob mb-25">
                  <Img fixed={data.PortraitDesktop.childImageSharp.fixed} alt="portrait" imgStyle={{ objectFit: 'contain' }} />
                </div>
              }
              rightSlot={
                <>
                  <Typography.Title className="clp-typography-title clp-typography-title_size_sm mb-25">
                    <div className="discovery-title" id="discover-section">
                      <p>
                        <span className="discover">
                          <b>{getTranslates(data.allStrapiClpPhilosophyPage, 'discover__header', language)}</b>{' '}
                        </span>
                        <span className="embellisseurs">
                          <i>{getTranslates(data.allStrapiClpPhilosophyPage, 'discover__header__LES', language)}</i>
                        </span>
                      </p>
                    </div>
                  </Typography.Title>

                  <Typography.Text className="clp-typography-text clp-typography-text_size_md">
                    {[1, 2, 3, 4].map(i => (
                      <p key={i} className="content-card mb-25">
                        {getTranslates(data.allStrapiClpPhilosophyPage, `discover__par${i}`, language)}
                      </p>
                    ))}
                  </Typography.Text>

                  <div className="land-img_ph-key-mobile hidden_desk">
                    <Img
                      fixed={data.PortraitMobile.childImageSharp.fixed}
                      alt="portrait small"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                </>
              }
            />
          </Container>
        </div>
        <StickyBtnBlock onClick={redirectHelper.redirectToNewGift} />
      </div>
    </>
  );
};

const scrollToDiscover = () => {
  document?.getElementById('discover-section')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const query = graphql`
  query {
    PhilosophyPreview: file(relativePath: { eq: "images/castle.jpg" }) {
      childImageSharp {
        fluid(quality: 90, sizes: "(min-width: 1119px) calc(50vw - 40px), 560px", maxWidth: 560) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    KeyDesktop: file(relativePath: { eq: "images/key.png" }) {
      childImageSharp {
        fixed(height: 512, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    KeyMobile: file(relativePath: { eq: "images/keyMobile.png" }) {
      childImageSharp {
        fixed(height: 256, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    Down: file(relativePath: { eq: "images/down.png" }) {
      childImageSharp {
        fixed(height: 9, width: 16) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    PortraitDesktop: file(relativePath: { eq: "images/julesPortrait.png" }) {
      childImageSharp {
        fixed(height: 430, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    PortraitMobile: file(relativePath: { eq: "images/julesPortrait.png" }) {
      childImageSharp {
        fixed(height: 250, quality: 100, traceSVG: { color: "#0f0d18" }) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    allStrapiClpLandCommon {
      edges {
        node {
          header__philosophy__en
          header__philosophy__fr
        }
      }
    }
    allStrapiClpPhilosophyPage {
      edges {
        node {
          header__en
          header__fr
          description__par1__en
          description__par1__fr
          description__par2__en
          description__par2__fr
          description__par3__en
          description__par3__fr
          description__par4__en
          description__par4__fr
          discover__header__en
          discover__header__fr
          discover__header__LES__en
          discover__header__LES__fr
          discover__par1__en
          discover__par1__fr
          discover__par2__en
          discover__par2__fr
          discover__par3__en
          discover__par3__fr
          discover__par4__en
          discover__par4__fr
        }
      }
    }
  }
`;
